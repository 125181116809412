<template>
  <v-card
    height="50px"
    style="margin:0"
  >
    <v-footer
      id="dashboard-core-footer"
      absolute
      padless
      style="padding:0"
    >
      <v-card
        flat
        tile
        width="100%"
        class="lighten-1"
        style="margin:0"
      >
        <v-card-text>
          <span class="h3 font-weight-medium">
            Copyright by DeepReader (2021)
          </span>
        </v-card-text>
      </v-card>
    </v-footer>
  </v-card>
</template>

<script>
  export default {
    name: 'DashboardCoreFooter',
  }
</script>

<style lang="sass">
  #dashboard-core-footer
    a
      font-size: .825rem
      font-weight: 500
      text-decoration: none
      text-transform: uppercase
</style>
